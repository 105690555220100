import React, { Component } from "react";
import { withRouter } from "react-router";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout } from "./Utility";
import customFetch from "./apicall/api";
import { PEButton, PEDiv, PEImg, PEInput } from "./permissionComponents";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_unit_level_id: "",
      parent_entity_name: "",
      max_unit_level_id: "",
      entity_name: "",
      effective_designation_id: "",
    };
  }
  componentDidMount() {
    document.title = "Dashboard";
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    const {
      api_token,
      branch_unit_level_id,
      parent_entity_name,
      max_unit_level_id,
      effective_designation_id,
    } = (this.props.location && this.props.location.state) || {};
    localStorage.setItem("api_token", api_token);

    this.setState({
      branch_unit_level_id: branch_unit_level_id,
      parent_entity_name: parent_entity_name,
      max_unit_level_id: max_unit_level_id,
      effective_designation_id: effective_designation_id,
    });

    // // AT-2143 | AT-2179 | Ankur Makavana | 12-07-2023 | conver post method to get method
    // const params = {
    //   userid: uid,
    // };
    // const queryParams = new URLSearchParams(params).toString();
    // fetch(process.env.REACT_APP_URL + "/getrole?" + queryParams, {
    //   method: "GET",
    //   headers: { "api-token": api_token },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (data.api_code === 4) {
    //       localStorage.clear();
    //       this.props.history.push("/");
    //     }
    //     console.log(data.max_unit_level_id);
    //     this.setState({
    //       branch_unit_level_id: data.max_unit_level_id,
    //       parent_entity_name: data.parent_entity_name,
    //       max_unit_level_id: data.max_unit_level_id,
    //       effective_designation_id: data.effective_designation_id,
    //     });
    //     localStorage.setItem("e_designation_id", data.effective_designation_id);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  render() {
    // console.log("Local storage uid",uid)
    const { effective_designation_id } = this.state;
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    return (
      <>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="logo-area">
            <PEDiv element_id="ProfileBtn" className="user_icon">
                <img
                  src={require("../assets/images/Profile.svg")}
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/Profile",
                      state: {
                        api_token: api_token,
                      },
                    });
                  }}
                  alt=""
                />
              </PEDiv>
              <img src={require("../assets/images/aceN Logo.png")} alt="" />
              <div className="log-out">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>

            <div className="dashboard-card-holder">
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/TO_slip",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>Fin Ops</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/supportticketlist",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>Support Ticket</h2>
                  </div>
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/Transaction_history",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>History</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>

              <div
                className={
                  effective_designation_id ==
                  "card card-border-small col-sm-6 py-2 disabletile"
                    ? ""
                    : "card card-border-small col-sm-6 py-2"
                }
                onClick={() => {
                  // this.props.history.push({
                  //   pathname: "/Transaction_history",
                  //   state: {
                  //     api_token: api_token,
                  //     effective_designation_id: effective_designation_id,
                  //   },
                  // });
                  console.log(
                    "REACT_APP_PAYLOAD_VIEW",
                    process.env.REACT_APP_PAYLOAD_VIEW
                  );
                  window.location.assign(
                    process.env.REACT_APP_PAYLOAD_VIEW +
                      "/payload?api_token=" +
                      api_token +
                      "&desc_id=" +
                      effective_designation_id
                  );
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>
                      Payload
                      {effective_designation_id == "" && (
                        <div className="nb-spinner t10-r10-25"></div>
                      )}
                    </h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/upi_autopay",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>UPI Autopay</h2>
                  </div>
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  window.location.assign(
                    process.env.REACT_APP_PAYLOAD_VIEW +
                      "/Esign?api_token=" +
                      api_token +
                      "&desc_id=" +
                      effective_designation_id +
                      "&uid=" +
                      localStorage.getItem("in_userid")
                  );
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>E-sign</h2>
                  </div>
                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(Dashboard);
